import React from "react";

const WispapaloozaTop = (props) => {
  return (
    <>
      <div className={"wispapalooza"}>
        <div className={"wispapalooza-container"}>
          <div className={"wispapaloozaTo-top-column"}>
            <div>
              <div className="wispapaloozaTo-heading">Meet Us in Person!</div>
              <p className="wispapaloozaTo-body">Head over to booth #149.</p>
              <p className="wispapaloozaTo-body">
              Connect with us and see how Paraqum Wi-Di can help fix your network issues.
              </p>
            </div>
            <div>
          <img
            src={"/assets/wispapalooza/wispapalooza.png"}
            alt={"product download left banner"}
            className={"wispapalooza-logo"}
          />
        </div>
          </div>
        </div>
        
      </div>
      <div>
        <img
          src={"/assets/wispapalooza/1.png"}
          alt={"product download left banner"}
          className={"img1-wispapalooza"}
        />
      </div>
    </>
  );
};

export default WispapaloozaTop;
