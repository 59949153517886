import React from "react";
import Boxdata from "./Boxdata";
const WiDiStandOut = (props) => {
  return (
    <>
      <div className={"wispapalooza-standout"}>
        <div className="wispapalooza-whoweare-container">
          <div className={"wispapaloozaTo-whychoos-column"}>
            <div className="wispapaloozaTo-specialities-heading">
              <div className="wispapalooza-whoweare-bold">
              Why choose Paraqum <br /> Wi-Di?
              </div>
              <div className="wispapalooza-whoweare-body">
              Here’s why you should try out our solution,<br/> be the change your subscribers want to see.
              </div>
              <div>
               <a href="/book-a-demo"> <button class="wispapalooza-button">Book a demo</button></a>
                </div>
            </div>
            <div>
              <div class="wrapper-widi">
                <div className="widi-box">
                  <Boxdata
                    image={"/assets/wispapalooza/WISPAPALOOZA 2022_Session Quality Monitoring_copy.png"}
                    title={"Session Quality Monitoring"}
                    data={
                      "Allows you to monitor and index the reliability of sessions in order to ensure the highest QoE for your subscribers"
                    }
                  />
                </div>
                <div className="widi-box">
                  <Boxdata
                    image={"/assets/wispapalooza/WISPAPALOOZA 2022_FUP Enforment_copy.png"}
                    title={"FUP Enforcement"}
                    data={
                      "Assures the proper enforcement of FUP with easy identification of FUP violators and guaranteed QoE for every subscriber."
                    }
                  />
                </div>
                <div className="widi-box">
                  <Boxdata
                    image={"/assets/wispapalooza/WISPAPALOOZA 2022_Millisecond Resolution_copy.png"}
                    title={"1 Millisecond Resolution "}
                    data={
                      "Provides an extremely granular bandwidth monitoring capability down to1 millisecond interval."
                    }
                  />
                </div>
                <div className="widi-box">
                  <Boxdata
                    image={"/assets/wispapalooza/WISPAPALOOZA 2022_DPI Tech_copy.png"}
                    title={"State-of-the-art Deep Packet Inspection (DPI) Technology"}
                    data={
                      "Application aware Traffic Shaping that allows precise control of application traffic, offering best QoE for all applications and subscribers"
                    }
                  />
                </div>
                <div className="widi-box">
                  <Boxdata
                    image={"/assets/wispapalooza/WISPAPALOOZA 2022_TCP Acceleration_copy.png"}
                    title={"TCP Acceleration Support"}
                    data={
                      "Improves subscriber QoE and network efficiency, without making changes to the network infrastructure or modifying the end applications."
                    }
                  />
                </div>
                <div className="widi-box">
                  <Boxdata
                    image={"/assets/wispapalooza/WISPAPALOOZA 2022_Multi-Level Shaping_copy.png"}
                    title={"Multi-Level and Priority Based Shaping"}
                    data={
                      "Maps the infrastructure topology : from subscriber plan through sector, backhaul and top link limits"
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <img
        src={"/assets/wispapalooza/5.png"}
        alt={"product download left banner"}
        className={"img2-wispapalooza"}
      />
    </>
  );
};

export default WiDiStandOut;
