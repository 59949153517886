import React from "react";

const WhoWeAre = (props) => {
  return (
    <>
      <div className={"wispapalooza-whoweare"}>
        <div className="wispapalooza-whoweare-container">
          <div className={"wispapaloozaTo-whoweare-column"}>
            <div className="wispapaloozaTo-specialities-heading">
              <div className="wispapalooza-whoweare-hr"></div>
              <div className="wispapalooza-whoweare-bold">
              What do <span className="wispapalooza-whoweare-blue">we do </span>?
              </div>
              <div className="wispapalooza-whoweare-body">
              If you are struggling to meet the ever so progressive bandwidth demands of your customers and ensuring the Quality of Experience (QoE) yet getting ROI sufficient to sustain the business, we are here to help!
Paraqum Wi-Di makes sure that your subscribers enjoy the best out of their networks at the expense of the lowest cost. We aim to optimize network usage and QoE with extremely granular control and visibility.
              </div>
            </div>
            <div className="video">
            <iframe width="100%" height="100%" src={"https://www.youtube.com/embed/hgYTzDx_tp0"} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WhoWeAre;
