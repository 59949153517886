import React from 'react'
import Header from '../../components/header'
import Footer from '../../components/footer'
import { Helmet } from "react-helmet";
import Card from './Card';
import CardCarousel from './CardCarousel';
import eventData from "./eventData.json";

const Events = () => {

  const cardItems = eventData.map(event => {
    return {
        id: event.id,
        eventName: event.eventName,
        eventYear: event.eventYear,
        bgImageLink: event.bgImageLink,
        status: event.status
    };
});


  return (
    <div className="events">
      <Helmet>
        <title>Paraqum Events</title>
        <meta name="description" content="Paraqum Events" />
        <meta
          name="keywords"
          content="Traffic shaping,network traffic management,bandwidth management,network traffic analysis,traffic analytics,network traffic control,Network Quality of Experience ,Network QoE,Network QoE Assurance,network traffic optimization,network traffic optimisation,network intelligence,network Quality of Service,network QoS,Session Quality Index,SQI"
        />
      </Helmet>

      <Header />

      <div className="events-header">
        <div className="events-bgoverlay">
          <h4>EVENTS</h4>
          <p>
          Discover Our Latest Innovations and Engagements
            <br />
            at Industry-Leading Events and Conferences
          </p>
        </div>
      </div>
{/* <div className='events-ongoing'>
  <span className='events-title-pre'></span>Latest Events
</div> */}
      <div className="current-event">

        <div className="current-event-left">
          <div className="current-event-image-container">
          <img  className='current-event-wisp-logo' src="/assets/events/wispapalooza2024/wispaplooza2024.png" alt="wispapalooza2024"/>

          <img src="/assets/events/wispapalooza2024/lasvegasNevada.jpg" alt="wispapalooza2024" />
          <div class="current-event-img-overlay"></div>
          </div>

        </div>
        
        <div className="current-event-right">
        <hr className="current-event-hr" />
        <div className='current-event-right-content'>
          <div className="current-event-right-content-upper">
          <p className='current-event-right-content-upper-date'>27-06-2024</p>
          <div className='current-event-right-dot'></div>
          <p className='current-event-right-content-upper-booth'>Booth No - 303</p>
          </div>
          <h1 className="current-event-right-content-title">
          WISPAPALOOZA- 2024
          </h1>
          <p className='current-event-right-content-des'>
          Paraqum Technologies is delighted to share the success of our participation as a proud sponsor of APRICOT 2024, held at the Athenee Hotel in Bangkok, Thailand from 21 February to 1 March 2024. This prestigious event brought together a diverse group of Internet engineers, networking experts, government representatives, and business leaders from around the globe.  
          </p>
          <a
                href={"/wispapalooza2024"}
        
                rel={"noreferrer"}
             > 
          <button className='current-event-more-info-btn'>
            MORE INFO
          </button>
          </a>

        </div>  
        </div>
      

        
      {/* <Card item={currentEvent}/> */}
      </div>

<div className="events-cardCarousel">
       <CardCarousel  items={cardItems} />
       </div>

      

      <Footer />
    </div>
  );
}

export default Events
