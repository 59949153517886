import React from "react";

const Boxdata = (props) => {
  return (
    <>
      <div className={"boxdata"}>
      <img src={props.image} alt={"product download left banner"} className={"box-image"} />
        <div className="boxdata-title">{props.title}</div>
        <div className="boxdata-data">{props.data}</div>
      </div>
    </>
  );
};

export default Boxdata;