import React from "react";
import Timedata from "./Timedata";
const WhereYouNeed = () => {
  return (
    <>
      <div className={"wispapalooza-wondering"}>
        <div className="wispapalooza-whoweare-container">
        {/* <div className="wispapaloozaTo-meetus-heading">
        <div className="wispapalooza-whoweare-hr"></div>
          <div className="wispapalooza-whoweare-bold">
          Meet <span className="wispapalooza-whoweare-blue">Us</span> </div> </div> */}
          <div className={"wispapaloozaTo-wondering-column"}>
            <div className="wispapaloozaTo-specialities-heading2">
              
              <div
                className="wispapalooza-whoweare-bold"
                style={{ marginTop: "100px", marginLeft: "100px" }}
              >
                Wondering where we’ll be at the event?
              </div>
              <div
                className="wispapalooza-wondering-body "
                style={{ marginLeft: "100px" }}
              >
                Here’s how to find us. We will be at booth #707.
              </div>
              <div class="wrapper-time">
                <div className="widi-time-box">
                <Timedata
                    image={"/assets/wispapalooza/time.png"}
                    title={"Tuesday, March 7th"}
                    data1={
                      "12.00pm - 6.15pm"
                    }
                    data2={"" }
                  />
                </div>
                <div className="widi-time-box">
                <Timedata
                    image={"/assets/wispapalooza/time.png"}
                    title={"Wednesday, March 8th"}
                    data1={
                      "12.00pm - 6.15pm"
                    }
                    data2={""}
                  />
                </div>
                <div className="widi-time-box">
                <Timedata
                    image={"/assets/wispapalooza/time.png"}
                    title={"Thursday, March 9th"}
                    data1={
                      "10.45am - 12.30pm"
                    }
                    data2={""}
                  />
                </div>
                <div className="widi-time-box">
                <Timedata
                    image={"/assets/wispapalooza/time.png"}
                    title={"Thursday, March 9th"}
                    data1={
                      "10.45am - 12.30pm"
                    }
                    data2={""}
                  />
                </div>
                
                </div>
            </div>
            <div>
              <div className="mapimage2"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WhereYouNeed;
