import React, { useState } from 'react';
const dropdownItems = [
    {
        id: 0,
        title: "Service Providers",
        icon: "/assets/icons/header/ServiceProviders.svg",
        sections: [
            {
                title: "ISP/Telco",
                url: "/isp_telco",
                column1: [
                    {
                        title: "Network Analyzer",
                        url: "/isp_telco/networkAnalyzer"
                    },
                    {
                        title: "Traffic Shaper",
                        url: "/isp_telco/trafficShaper"
                    },
                    {
                        title: "SMS",
                        url: "/isp_telco/sms"
                    }
                ],
                column2: [

                ]
            },
            {
                title: "WISP",
                url: "/wisp",
                column1: [
                    {
                        title: "Wi-Di",
                        url: "/wisp/wi-di"
                    },
                    {
                        title: "Wi-Di Lite",
                        url: "/wisp/wi-di-lite"
                    }
                ],
                column2: [
                    
                ]
            }
        ],
        direct: [],
        width: "full"
    },
    {
        id: 1,
        title: "Enterprise",
        icon: "/assets/icons/header/EnterpriseSection.svg",
        width: "full",
        sections: [
            {
                title: "CeySeries",
                url: "/ceySeries",
                column1: [
                    {
                        title: "CeyAnalyst",
                        url: "/ceySeries/ceyAnalyst"
                    },
                    {
                        title: "CeyMarshal",
                        url: "/ceySeries/ceyMarshal"
                    },
                    {
                        title: "CeyFlow",
                        url: "/ceySeries/ceyFlow"
                    }
                ],
                column2: [
                    {
                        title: "CeyGalaxy",
                        url: "/ceySeries/ceyGalaxy"
                    },
                    {
                        title: "CeyBroker",
                        url: "/ceySeries/ceyBroker"
                    },
                    {
                        title: <def>CeyDirect-<i>i</i></def>,
                        url: "/ceySeries/ceyDirectI"
                    }
                ]
            },
            {
                title: "SME",
                url: "/sme",
                column1: [
                    {
                        title: "EX-Series",
                        url: "/sme/ex-series"
                    }
                ],
                column2: [
                    
                ]
            }
        ],
        direct: [],
    },
    {
        id: 2,
        title: "Company",
        sections: [],
        direct: [
            {
                title: "About",
                url: "/about"
            },
            {
                title: "Team",
                url: "/team"
            },
            {
                title: "News",
                url: "/news"
            }
        ],
        width: "half",
        icon: "/assets/icons/header/About.svg",
    },
    {
        id: 3,
        title: "Resources",
        sections: [],
        direct: [
            {
                title: "Case Studies",
                url: "/case-studies"
            },
            {
                title: "Use Cases",
                url: "/use-cases"
            },
            {
                title: "Datasheets",
                url: "/datasheets"
            },
        ],
        width: "half",
        icon: "/assets/icons/header/Resources.svg",
    },
    {
        id: 4,
        title: "Contact Us",
        sections: [],
        direct: [],
        width: "none",
        url: "/contact"
    }
];

const Headerwispapalooza = (props) => {
    const [dropdown,setDropdown] = useState(null);
    const [expanded,setExpanded] = useState(false);
    const [mobileSection,setMobileSection] = useState(null);

    const toggleMiniDropdown = (section) => {
        if(mobileSection === section) {
            setMobileSection(null);
        } else {
            setMobileSection(section);
        }
    }

    return <div className={"header-container"}>
        <div className={props.transparent === true ? "header transparent" : "header"}>

            <div className={dropdown === null || dropdown.width === "none" ? "header-dropdown-container hidden" : dropdown.width === "half" ? "header-dropdown-container half" : "header-dropdown-container"} onMouseLeave={()=>setDropdown(null)} >
                <div className={"header-dropdown"}>
                {
                    dropdown !== null && dropdown.width === "full" ?
                    <div className={"col col1"}>
                        <a href={ dropdown.sections[0].url }><div className={"col-header"}>{ dropdown.sections[0].title }</div></a>
                        <div className={"col-container"}>
                        {
                            dropdown.sections[0].column1.map((item)=>{
                                return <a href={item.url}>
                                    <div className={"col-header-item"}>{item.title}</div>
                                </a>
                            })
                        }
                        </div>
                    </div>:null
                }
                {
                    dropdown !== null && dropdown.width === "full" ?
                    <div className={"col col2"}>
                        <div className={"col-header"}>&nbsp;</div>
                        <div className={"col-container"}>
                        {
                            dropdown.sections[0].column2.map((item)=>{
                                return <a href={item.url}>
                                    <div className={"col-header-item"}>{item.title}</div>
                                </a>
                            })
                        }
                        </div>
                    </div>:null
                }
                <hr className={"vertical-hr"}></hr>
                <div className={"col col3"}>
                    {
                        dropdown !== null && dropdown.sections.length > 1 ?
                        <a href={ dropdown.sections[1].url }><div className={"col-header"}>{ dropdown.sections[1].title }</div></a>
                        : null 
                    }
                    <div className={"col-container"}>
                    {
                        dropdown !== null && dropdown.sections.length > 1 ?
                        dropdown.sections[1].column1.map((item)=>{
                            return <a href={item.url}>
                                <div className={"col-header-item"}>{item.title}</div>
                            </a>
                        })
                        : null
                    }
                    {
                        dropdown !== null && dropdown.direct.length > 0 ?
                        <div className={"col-header"}></div>
                        : null
                    }
                    {
                        dropdown !== null && dropdown.direct.length > 0 ?
                        dropdown.direct.map((item)=>{
                            return <a href={item.url}>
                                <div className={"col-header-item"}>{item.title}</div>
                            </a>
                        })
                        : null
                    }
                    </div>
                </div>
                <div className={"col col4"}>
                    { dropdown !== null  ? <img src={dropdown.icon} alt={dropdown.title}/> : null }
                </div>
                </div>
            </div>
            <div className={"header-left"}>
                <a href={"/"}><img src="/assets/icons/common/pq_white.png" alt="Paraqum Technologies" /></a>
            </div>
            <div className={"header-right"}>
                <div className={"header-sections"}>
                    {
                        dropdownItems.map((item, index)=>{
                            return <div className={dropdown === null ? "header-section" : index === 4 &&  dropdown.id === index ? "header-section header-section-contact selected" : dropdown.id === index ? "header-section selected" : "header-section"} onMouseEnter={()=>setDropdown(item)} key={"title_"+index}>
                                {dropdown !== null && dropdown.width === "none" ? <a href={item.url}>{item.title}</a> : <def>{item.title}</def>}
                                <img className={index === 4 ? "header-selected-bar-hidden":"header-selected-bar"} alt="paraqum selected header" src={"/assets/icons/header/dropdownIndicator.svg"}/>
                            </div>
                        })
                    }
                </div>
                <div className={"header-solution"}>
                    <a href={"https://embedded.paraqum.com/"} target={"_blank"} rel={"noreferrer"}>
                        Paraqum Embedded&nbsp;<span><svg fill="#FBC21D" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 50 50" width="15px" height="15px"><path d="M 33.40625 0 C 32.855469 0.0507813 32.449219 0.542969 32.5 1.09375 C 32.550781 1.644531 33.042969 2.050781 33.59375 2 L 46.5625 2 L 25.6875 22.90625 C 25.390625 23.148438 25.253906 23.535156 25.339844 23.910156 C 25.425781 24.28125 25.71875 24.574219 26.089844 24.660156 C 26.464844 24.746094 26.851563 24.609375 27.09375 24.3125 L 48 3.4375 L 48 16.40625 C 47.996094 16.765625 48.183594 17.101563 48.496094 17.285156 C 48.808594 17.464844 49.191406 17.464844 49.503906 17.285156 C 49.816406 17.101563 50.003906 16.765625 50 16.40625 L 50 0 L 33.59375 0 C 33.5625 0 33.53125 0 33.5 0 C 33.46875 0 33.4375 0 33.40625 0 Z M 2 10 C 1.476563 10 0.941406 10.183594 0.5625 10.5625 C 0.183594 10.941406 0 11.476563 0 12 L 0 48 C 0 48.523438 0.183594 49.058594 0.5625 49.4375 C 0.941406 49.816406 1.476563 50 2 50 L 38 50 C 38.523438 50 39.058594 49.816406 39.4375 49.4375 C 39.816406 49.058594 40 48.523438 40 48 L 40 18 C 40.003906 17.640625 39.816406 17.304688 39.503906 17.121094 C 39.191406 16.941406 38.808594 16.941406 38.496094 17.121094 C 38.183594 17.304688 37.996094 17.640625 38 18 L 38 48 L 2 48 L 2 12 L 32 12 C 32.359375 12.003906 32.695313 11.816406 32.878906 11.503906 C 33.058594 11.191406 33.058594 10.808594 32.878906 10.496094 C 32.695313 10.183594 32.359375 9.996094 32 10 Z"/></svg></span>
                    </a>
                </div>
                <div>
               <a href="/book-a-demo"> <button class="wispapalooza-header-button">Book a demo</button></a>
                </div>
                
            </div>
        </div>
        <div className={expanded ? "header-mobile expanded" : props.transparent === true ? "header-mobile transparent" : "header-mobile" }>
            <div className={expanded ? "header-mobile-dropdown expanded" : props.transparent === true ? "header-mobile-dropdown transparent" : "header-mobile-dropdown"}>
                <div className={expanded ? "hamburg expanded" : "hamburg"} onClick={()=>setExpanded(!expanded)}>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
                <div className={"header-mobile-logo"}>
                    <a href={"/"}><img src="/assets/icons/common/pq_white.png" alt="Paraqum Technologies" /></a>
                </div>
                
                <div className={"header-mobile-content"}>
                    <div className={"header-mobile-content-wrapper"}>
                        <div className={"header-mobile-content-types"}>
                            <img src={mobileSection === 0 ? "/assets/icons/header/ISP_TELCOwhite.svg" : "/assets/icons/header/ISP_TELCO.svg"} onClick={()=>toggleMiniDropdown(0)} alt={"ISP/Telco"}/>
                            <img src={mobileSection === 1 ? "/assets/icons/header/WISPwhite.svg" : "/assets/icons/header/WISP.svg"}  onClick={()=>toggleMiniDropdown(1)} alt={"WISP"}/>
                            <img src={mobileSection === 2 ? "/assets/icons/header/ENTERPRISEwhite.svg" : "/assets/icons/header/ENTERPRISE.svg"}  onClick={()=>toggleMiniDropdown(2)} alt={"Enterprise"}/>
                            <img src={mobileSection === 3 ? "/assets/icons/header/SMEwhite.svg" : "/assets/icons/header/SME.svg"}  onClick={()=>toggleMiniDropdown(3)} alt={"SME"}/>
                        </div>

                        <br/>

                        <div className={mobileSection === 0 ? "header-mobile-content-section" : "header-mobile-content-section hidden"}>
                            <a href={"/isp_telco"}><h5>ISP/Telco</h5></a>
                            <div className={"row"}>
                                <a href={"/isp_telco/trafficShaper"}>Traffic Shaper</a>
                                <a href={"/isp_telco/networkAnalyzer"}>Network Analyzer</a>
                            </div>
                            <div className={"row"}>
                                <a href={"/isp_telco/subscriberManager"}>Subscriber Manager</a>
                            </div>
                        </div>

                        <div className={mobileSection === 1 ? "header-mobile-content-section" : "header-mobile-content-section hidden"}>
                            <a href={"/wisp"}><h5>WISP</h5></a>
                            <div className={"row"}>
                                <a href={"/wisp/wi-di"}>Wi-Di</a>
                                <a href={"/wisp/wi-di-lite"}>Wi-Di Lite</a>
                            </div>
                        </div>

                        <div className={mobileSection === 2 ? "header-mobile-content-section" : "header-mobile-content-section hidden"}>
                            <a href={"/ceySeries"}><h5>Enterprise (Cey Series)</h5></a>
                            <div className={"row"}>
                                <a href={"/ceySeries/ceyAnalyst"}>CeyAnalyst</a>
                                <a href={"/ceySeries/ceyMarshal"}>CeyMarshal</a>
                            </div>
                            <div className={"row"}>
                                <a href={"/ceySeries/ceyFlow"}>CeyFlow</a>
                                <a href={"/ceySeries/ceyGalaxy"}>CeyGalaxy</a>
                            </div>
                            <div className={"row"}>
                                <a href={"/ceySeries/ceyBroker"}>CeyBroker</a>
                                <a href={"/ceySeries/ceyDirect-i"}>CeyDirect-<i>i</i></a>
                            </div>
                        </div>

                        <div className={mobileSection === 3 ? "header-mobile-content-section" : "header-mobile-content-section hidden"}>
                            <a href={"/sme"}><h5>SME</h5></a>
                            <div className={"row"}>
                                <a href={"/sme/ex-series"}>Ex-Series</a>
                            </div>
                        </div>

                        <br/>

                        <div className={"header-mobile-content-section"}>
                            <h5>Company</h5>
                            <div className={"row"}>
                                <a href={"/about"}>About</a>
                                <a href={"/team"}>Team</a>
                            </div>
                            <div className={"row"}>
                                <a href={"/news"}>News</a>
                                <a href={"/contact"}>Contact Us</a>
                            </div>
                        </div>

                        <br/>

                        <div className={"header-mobile-content-section"}>
                            <h5>Resources</h5>
                            <div className={"row"}>
                                <a href={"/case-studies"}>Case Studies</a>
                                <a href={"/use-cases"}>Use Cases</a>
                            </div>
                            <div className={"row"}>
                                <a href={"/datasheets"}>Datasheets</a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"header-mobile-dropdown-logo"}>
                    <a href={"/"}><img src="/assets/icons/common/pq_white.png" alt="Paraqum Technologies" /></a>
                </div>
            </div>
        </div>
    </div>
}

export default Headerwispapalooza;